<template>
  <div class="teenagerHabit">
    <h3>你有哪些日常的生活和学习时的用眼习惯呢？</h3>
    <div class="content">
      <p style="width: 5em;text-align: left;">用眼环境</p>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/eyeEnviro1.jpg" alt="">
        <el-radio v-model="hjList" label="0" border>台灯</el-radio>
      </div>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/eyeEnviro2.jpg" alt="">
        <el-radio v-model="hjList" label="1" border>大灯</el-radio>
      </div>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/eyeEnviro4.jpg" alt="">
        <el-radio v-model="hjList" label="2" border>自然灯</el-radio>
      </div>

      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/eyeEnviro3.jpg" alt="">
        <el-radio v-model="hjList" label="3" border>台灯+大灯</el-radio>
      </div>
    </div>
    <div class="content">
      <p>写作业姿势</p>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/homework_pose1.png" alt="">
        <el-radio v-model="zsList" label="0" border>标准坐姿</el-radio>
      </div>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/homework_pose2.png" alt="">
        <el-radio v-model="zsList" label="1" border>坐姿前倾</el-radio>
      </div>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/homework_pose3.png" alt="">
        <el-radio v-model="zsList" label="2" border>坐姿仰后</el-radio>
      </div>
    </div>
    <div class="content">
      <p style="width: 5em;text-align: left;">握笔姿势</p>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/finger_ cross_single.png" alt="">
        <el-radio v-model="wbList" label="0" border>拇指与食指交叉</el-radio>
      </div>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/finger_ touch_single.png" alt="">
        <el-radio v-model="wbList" label="1" border>拇指与食指相碰</el-radio>
      </div>
      <div class="boxs">
        <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/finger_notTouch_single.png" alt="">
        <el-radio v-model="wbList" label="2" border>拇指与食指不相碰</el-radio>
      </div>
    </div>

    <div class="teenager">
      <div class="teenager-box">
        <p>写作业和阅读</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_readingTime" placeholder="请输入内容"></el-input>
        <p>小时/天</p>
      </div>
      <div class="teenager-box">
        <p>使用电子产品</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_electronicTime" placeholder="请输入内容"></el-input>
        <p>小时/天</p>
      </div>
      <div class="teenager-box">
        <p>每天睡眠时长</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_sleepTime" placeholder="请输入内容"></el-input>
        <p>小时/晚</p>
      </div>
      <div class="teenager-box">
        <p>累计户外运动</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_outdoorTime" placeholder="请输入内容"></el-input>
        <p>小时/天</p>
      </div>
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.S_OP_ICH_remark3">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "teenagerHabit",
  data() {
    return {
      zy: '',
      dz: "",
      sm: "",
      yd: '',
      hjList: '',
      zsList: '',
      wbList: '',
      postData: {}
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        if (o) {
          this.$store.commit('physicianVisits/upTeenagerData', {type: 3, obj: n})
        }
      },
      deep: true,
      immediate: true
    },
    "$store.state.physicianVisits.isgetData": function (n, o) {
      setTimeout(() => {
        let obj = this.$store.state.physicianVisits.teenagerYyxg,
            datas = JSON.parse(JSON.stringify(this.$store.state.physicianVisits.teenager))
        if (datas.S_OP_ChooseId) {
          for (let key in obj) {
            obj[key] = datas.S_OP_Json[key]
          }
        }
        this.postData = obj
        this.hjList = this.clArr(this.postData.S_OP_ICH_eyesEnvirArray)
        this.zsList = this.clArr(this.postData.S_OP_ICH_homeworkArray)
        this.wbList = this.clArr(this.postData.S_OP_ICH_writtingPostArray)
      }, 500)
    },
    hjList: function (e) {
      if (!e) return []
      let arr = [0,0,0,0]
      arr[e] = 1
      arr = arr.join(',')
      this.$set(this.postData, 'S_OP_ICH_eyesEnvirArray', arr)
    },
    zsList: function (e) {
      if (!e) return []
      let arr = [0,0,0]
      arr[e] = 1
      arr = arr.join(',')
      this.$set(this.postData, 'S_OP_ICH_homeworkArray', arr)
    },
    wbList: function (e) {
      if (!e) return []
      let arr = [0,0,0]
      arr[e] = 1
      arr = arr.join(',')
      this.$set(this.postData, 'S_OP_ICH_writtingPostArray', arr)
    },
    /*'$store.state.physicianVisits.xzConsulting': function (n, o) {
      if (n && n != o) {
        this.postData = this.$store.state.physicianVisits.teenager
        this.hjList = this.clArr(this.postData.S_OP_ICH_eyesEnvirArray)
        this.zsList = this.clArr(this.postData.S_OP_ICH_homeworkArray)
        this.wbList = this.clArr(this.postData.S_OP_ICH_writtingPostArray)
      }
    },
    '$store.state.users.CsUser': function (n, o) {
      if (n) {
        this.srpp = this.$store.state.users.CsUser.CustomerBirthday
        this.sex = Number(this.$store.state.users.CsUser.CustomerGender)
        let age = this.$store.state.users.CsAge
        if (age && age < 18) {
          this.srcList = this.teenagerList
          this.isAdult = false
          this.srpp = age
        }
      }else {
        this.postData = {}
        this.checkList = []
        this.srpp = ''
        this.sex = -1
      }
    }*/
  },
  created() {

  },
  methods: {
    clArr(arr) {
      if (!arr) {
        return ''
      }
      let arrs = arr.split(',')
      for (let i = 0; i < arrs.length; i++) {
        if (Number(arrs[i])) {
          return i.toString()
        }
      }
    }, //处理用眼环境等数组
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .fxk .el-checkbox__inner::after {
    width: 8px;
    height: 16px;
    left: 10px;
    top: 2px;
  }
  ::v-deep .fxk .el-checkbox__inner {
    width: 30px;
    height: 30px;
  }
  ::v-deep .fxk .el-checkbox__label {display: none;}


  .teenagerHabit {
    width: 85vw;
    flex-wrap: wrap;
    padding: 0 2vw;
    padding-top: 0.3rem;
  }
  h3 {
    padding-bottom: 0.3rem;
  }
  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-top: 0.15rem;
    img {
      display: block;
      width: 1.5rem;
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 0.1rem;
    }
    p {padding-right: 0.5rem}
    .boxs {
      padding-right: 0.4rem;
      span {margin-right: 0.05rem}
    }
  }

  .teenager {
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
    padding: 0 2.5vw;
    margin-bottom: 0.3rem;
    .teenager-box {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-right: 0.3rem;
      padding-top: 0.2rem;
    }
    .teenager-sr {
      width: 1.5rem;
      margin: 0 0.1rem;
    }
  }
  .bz {
    width: 76vw;
    padding: 0 3vw;
    text-align: left;
    padding-top: 0.3rem;
  }
</style>
